import React, { ReactNode } from "react";
import { Parallax } from "react-scroll-parallax";
import Excerpt from "./excerpt";
import "./layout.css";

const ContainerWithHeaderOnly = ({
  containerTitle,
  children,
  excerpt,
  className,
  highlight,
  excerptEnd
}: {
  excerpt?: string;
  children: ReactNode;
  className?: string;
  containerTitle?: string;
  highlight?: string;
  excerptEnd?: string;
}) => {
  return (
    <Parallax className={className}>
      <div className="container">
        <h1>{containerTitle}</h1>
        <div>{children}</div>
      </div>
    </Parallax>
  );
};

export default ContainerWithHeaderOnly;
