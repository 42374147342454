import { graphql, useStaticQuery } from "gatsby";
import Img, { FluidObject } from "gatsby-image";
import React, { CSSProperties } from "react";
import FeatureContainerFour from "../featureContainerFour";

export default () => {
  return (
    <div className="four-features-grid">
      <FeatureContainerFour
        headerIconSource={require(`../../../assets/service_icon_1.png`)}
        excerpt="Adapt your IT products whatever the support, the operating system or the browser, for your Internet users."
        // style={{ width: `40px` }}
      />
      <FeatureContainerFour
        headerIconSource={require(`../../../assets/service_icon_2.png`)}
        excerpt="The number of tests to perform is exponential before the release of your releases."
        // style={{ width: `40px` }}
      />
      <FeatureContainerFour
        headerIconSource={require(`../../../assets/service_icon_3.png`)}
        excerpt="We make them for you on more than 70 platforms. 20 to 30% of the production time is saved."
        // style={{ height: `50px`, width: `58.7px` }}
      />
      <FeatureContainerFour
        headerIconSource={require(`../../../assets/service_icon_4.png`)}
        excerpt="Your teams can focus on growing your business."
        // style={{ width: `63px` }}
      />
    </div>
  );
};
