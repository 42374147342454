import React from "react";
// import { Trans } from "@lingui/macro";
import { withTranslation } from "react-i18next";
// import { withTranslation } from "react-i18next";
import { Translation } from "react-i18next";
import { Trans } from "react-i18next";
import Container from "../container";
import "../layout.css";
import ServicesFourFeatures from "./servicesFourFeatures";
import ServicesImageContainerWithHeaderOnly from "./servicesImageContainerWithHeaderOnly";

class Services1 extends React.Component {
  public render() {
    return (
      <div>
        <ServicesImageContainerWithHeaderOnly
          className="background-image1"
          excerpt="Offer an"
          highlight="optimal digital experience"
          excerptEnd="to your customers"
        />
        <Container containerTitle="Testing Activity">
          <div
            className="feature-container-div"
            style={{ textAlign: `center`, marginLeft: `0px` }}
          >
            <ServicesFourFeatures />
          </div>
          {/* <Translation>
            {t => (
              <h1>
                {/* <Trans id="2 Processes" /> */}
          {/* {t("2 processes")}
              </h1>
            )} */}
          {/* </Translation> */}
          <h1>
            <Trans i18nKey="2 processes" />
          </h1>
          <div className="services1-processes-div">
            <div className="services1-processes">
              <div className="services1-processes-header">
                <img
                  className="services1-processes-image"
                  src="https://www.surya-digital.com/wp-content/uploads/2017/10/surya_1.png"
                  alt="Lifecycle Stage 1"
                />
                <h3 style={{ fontSize: `inherit` }}>
                  <Trans i18nKey="Your site or application is already existing." />
                </h3>
              </div>
              <p>
                <Trans i18nKey="We perform comprehensive, technical and ergonomic testing of your digital products. We analyze and identify dysfunctions.Together we find the right solutions." />
              </p>
            </div>
            <div className="services1-processes">
              <div className="services1-processes-header">
                <img
                  className="services1-processes-image"
                  src="https://www.surya-digital.com/wp-content/uploads/2017/10/surya_2.png"
                  alt="Lifecycle Stage 2"
                />
                <h3 style={{ fontSize: `inherit` }}>
                  <Trans i18nKey="Your site or application is in the development phase" />
                </h3>
              </div>
              <p>
                <Trans i18nKey="We offer direct integration into your production process. The tests are carried out throughout the design. Our engineers solve anomalies as soon as they are diagnosed." />
              </p>
            </div>
          </div>
          <h2 className="services-header-lower">
            <Trans i18nKey="Our experts fix the most common bugs, without touching the features." />
            {
              <p
                className="colored-p"
                style={{ display: `block`, marginTop: `10px` }}
              >
                <Trans i18nKey="We guarantee a Quality Assurance (QA) compliant." />
              </p>
            }
          </h2>
        </Container>
      </div>
    );
  }
}

export default withTranslation()(Services1);
