import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import { url } from "inspector";
import React from "react";
import tilt_border from "../../../assets/tilt_border.png";
import tilt_border_footer from "../../../assets/tilt_border_footer.png";
import Excerpt from "../excerpt";

export default ({
  className,
  excerpt,
  highlight,
  excerptEnd
}: {
  className?: string;
  excerpt?: string;
  highlight?: string;
  excerptEnd?: string;
}) => {
  return (
    <div style={{ overflow: `hidden` }}>
      <div
        style={{
          marginBottom: `-7.5%`,
          position: `relative`,
          zIndex: 5
        }}
      >
        <img
          className="container-footer"
          alt="Top Border"
          src={tilt_border_footer}
          //   src={}
        />
      </div>{" "}
      <div className={className}>
        <Excerpt
          excerpt={excerpt}
          excerptEnd={excerptEnd}
          highlight={highlight}
        />
      </div>
      <div style={{ marginTop: `-7.5%`, overflow: `hidden` }}>
        <img
          className="container-header"
          alt="Bottom Border"
          src={tilt_border}
        />
      </div>
    </div>
  );
};
