import React from "react";
import { Trans } from "react-i18next";
import Container from "../container";
import ImageContainer from "../imageContainer";
import ServicesImageContainer from "./servicesImageContainer";

class Services3 extends React.Component {
  public render() {
    return (
      <div>
        <ServicesImageContainer
          className="background-image3"
          excerpt="Get relevant data and"
          highlight="increase your performance."
        />
        <Container>
          <div className="services3">
            <div className="services3-left-div">
              <h1 style={{ marginBottom: `30px` }}>Data Scientists</h1>
              <p>
                <Trans i18nKey="The" />{" "}
                {
                  <p className="colored-p">
                    <Trans i18nKey="Bangalore University of Science and Technology" />
                  </p>
                }{" "}
                <Trans i18nKey="is one of the most advanced centers in the processing and analysis of big data." />
              </p>
              <p>
                <Trans i18nKey="The best PHDs in the field are trained in this institution, and the biggest players in big data are there." />
              </p>
              <p>
                <Trans i18nKey="In partnership with the University of Bangalore, we set up centers of expertise specialized in your sector." />
              </p>
              <p>
                <Trans i18nKey="Our doctoral students in mathematics, statistics, or specialized in other technologies, formulate advanced algorithms. By precisely defining your search, our algorithms make it possible to extract the data generating your growth." />
              </p>
            </div>
            <div className="services3-image-div">
              <img
                className="sevices3-image "
                src="https://www.surya-digital.com/wp-content/uploads/2017/10/data-img.png"
                alt="Service 3"
              />
            </div>
          </div>
        </Container>
      </div>
    );
  }
}

export default Services3;
