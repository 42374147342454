import React from "react";
import { Trans } from "react-i18next";
import Container from "../container";
import ServicesImageContainer from "./servicesImageContainer";
import ServicesThreeFeatures from "./servicesThreeFeatures";

class Services2 extends React.Component {
  public render() {
    return (
      <div>
        <ServicesImageContainer
          className="background-image2"
          excerpt="Choose"
          highlight="the experience"
          excerptEnd="to make your Apps."
        />
        <Container containerTitle="IOS & Android Apps Development">
          <ServicesThreeFeatures />
          <h2 className="services-header-lower">
            <Trans i18nKey="From design to production, our developers and technicians produce" />
            {
              <p className="colored-p">
                {" "}
                <Trans i18nKey="software guaranteed to Quality Assurance (QA) standards." />
              </p>
            }
          </h2>
        </Container>
      </div>
    );
  }
}

export default Services2;
