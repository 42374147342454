import React from "react";
import Layout from "../components/layout";
import "../components/layout.css";
import Services1 from "../components/services/services-1";
import Services2 from "../components/services/services-2";
import Services3 from "../components/services/services-3";
import Services4 from "../components/services/services-4";
import { withTranslation } from "react-i18next";

class Services extends React.Component {
  public render() {
    return (
      <Layout>
        <Services1 />
        <Services2 />
        <Services3 />
        <Services4 />
      </Layout>
    );
  }
}

export default withTranslation()(Services);
