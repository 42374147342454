import { graphql, useStaticQuery } from "gatsby";
import Img, { FluidObject } from "gatsby-image";
import React, { CSSProperties } from "react";
import FeatureContainerThree from "../featureContainerThree";

export default () => {
  return (
    <div className="three-features-grid">
      <FeatureContainerThree
        excerpt="Create quality apps for your users"
        // style={{ width: `56px` }}
        headerIconSource={require(`../../../assets/star_1.png`)}
      />
      <FeatureContainerThree
        excerpt="The development of Apps on iOS is provided by a team of engineers who worked for Apple."
        // style={{ width: `40px` }}
        headerIconSource={require(`../../../assets/star_2.png`)}
      />
      <FeatureContainerThree
        excerpt="Our technicians use all existing programming languages ​​on Android."
        // style={{ width: `45px` }}
        headerIconSource={require(`../../../assets/star_3.png`)}
      />
    </div>
  );
};
