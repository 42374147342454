import React from "react";
import { Trans } from "react-i18next";
import ContainerWithHeaderOnly from "../containerWithHeaderOnly";
import ImageContainer from "../imageContainer";
import ServicesImageContainer from "./servicesImageContainer";

class Services4 extends React.Component {
  public render() {
    return (
      <div>
        <ServicesImageContainer
          className="background-image4"
          excerpt="Boost"
          highlight="your business performance."
        />
        <ContainerWithHeaderOnly>
          <div className="services-outer-div">
            <div className="services4">
              <h1 style={{ marginBottom: `30px` }}>
                <Trans i18nKey="Dynamics AX Competence Center" />
              </h1>
              <p>
                <Trans i18nKey="Take advantage of our 150 engineers to optimize the use of" />{" "}
                {
                  <p className="colored-p">
                    <Trans i18nKey="Microsoft Dynamics AX" />
                  </p>
                }
                .
              </p>
              <p>
                <Trans i18nKey="We propose :" />
              </p>
              <ul style={{ padding: `0px` }}>
                <li className="services4-li">
                  <span className="services4-li-icon">></span>{" "}
                  <Trans i18nKey="Specific development adapted to the needs of your company." />
                </li>
                <li className="services4-li">
                  <span className="services4-li-icon">></span>{" "}
                  <Trans i18nKey="Consulting-parameter setting to make the most of the capabilities of your ERP." />
                </li>
                <li className="services4-li">
                  <span className="services4-li-icon">></span>{" "}
                  <Trans i18nKey="From data migration to your new platform." />
                </li>
                <li className="services4-li">
                  <span className="services4-li-icon">></span>{" "}
                  <Trans i18nKey="Data collection and analysis to help you with your decision strategy." />
                </li>
              </ul>
              <h4 style={{ color: `deepskyblue` }}>
                <Trans i18nKey="Well used, this package reduces the risk of error and becomes a major asset of your success." />
              </h4>
            </div>
            <div className="services4-image-div">
              <img
                alt="Microsft Dynamics"
                src="https://www.surya-digital.com/wp-content/uploads/2017/10/microsoft_dynamic.png"
                style={{ width: `100%` }}
              />
            </div>
          </div>
        </ContainerWithHeaderOnly>
      </div>
    );
  }
}

export default Services4;
